<template>
  <div class="footer__middle">
    <div class="footer__certificates">
      <div class="certificate">
        <img
          width="43.38"
          height="48"
          class="certificate__image certificate__hipaa"
          src="@/assets/icons/logos/hipaa-mark.svg?url"
          alt="hipaa logo icon"
        />
        <p class="certificate__text">
          {{ $t('common.footer.cert_hipaa') }}
        </p>
      </div>
      <div class="certificate">
        <img
          class="certificate__image certificate__ce"
          width="40.73"
          height="40.73"
          src="@/assets/icons/logos/gdpr.svg?url"
          alt="gdpr logo icon"
        />
        <p class="certificate__text">
          {{ $t('common.footer.cert_gdpr') }}
        </p>
      </div>
      <div class="certificate">
        <img
          width="47.36"
          height="47"
          class="certificate__image certificate__dekra"
          src="@/assets/icons/logos/dekra.png?url"
          alt="iso logo icon"
        />
        <p class="certificate__text">ISO 27001:2017</p>
      </div>
      <div class="certificate">
        <img
          width="40"
          height="41.2"
          class="certificate__image certificate__gppr"
          src="@/assets/icons/logos/iso.svg?url"
          alt="certificate gppr logo icon"
        />
        <p class="certificate__text">ISO 13485:2016</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  &__middle {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray-200);
    border-top: 1px solid var(--gray-200);
    padding: 48px 0;

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
      justify-content: space-between;
      padding: 40px 0;
      border-bottom: none;
    }
  }

  &__certificates {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 32px;

    @media (min-width: $breakpoint-lg) {
      width: 100%;
      flex-direction: row;
      margin: 0 auto;
      gap: 80px;
    }

    .certificate {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $breakpoint-lg) {
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }

      &__image {
        width: 40px;
        margin-right: 12px;
      }

      &__hipaa {
        width: 43.38px;
        height: 48px;
        margin-right: 11px;
      }

      &__ce {
        width: 40.74px;
      }

      &__gdpr {
        width: 40.39px;
      }

      &__dekra {
        width: auto;
      }

      &__text {
        font-size: var(--font-size-paragraph-xs);
        color: var(--cobalt-800);
        margin-bottom: 0;
        line-height: 18px;
        letter-spacing: 0;
      }
    }
  }
}
</style>
