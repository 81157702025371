<template>
  <div class="footer__language">
    <div
      v-click-outside="closeList"
      class="language-picker"
      :class="{ 'language-picker--open': languageListIsOpen }"
      :aria-hidden="!languageListIsOpen"
      tabindex="0"
      @mouseenter="openList"
      @mouseleave="closeList"
      @focusin="openList"
    >
    <BaseIconSvg
        width="24"
        height="24"
        name="language-planet"
        class="language-picker__planet"
      />
      <span class="language-picker__text">
        {{ currentLocale && currentLocale.name }}
      </span>
      <div class="icon icon__chevron" />
      <ClientOnly>
        <transition name="slide-x">
          <div v-if="languageListIsOpen" class="language-list-wrapper">
            <ul class="language-list">
              <li tabindex="0">
                <span
                  class="language-list__link language-list__link--selected"
                  @click="currentLocale && switchLanguage(currentLocale.code)"
                >
                  {{ currentLocale?.name }}
                </span>
              </li>
              <li
                v-for="lang in availableLocales"
                :key="lang.code"
                tabindex="-1"
              >
                <nuxt-link
                  :aria-disabled="isDisabled(lang.code)"
                  tabindex="0"
                  :class="[
                    'language-list__link',
                    {
                      'language-list__link--disabled': isDisabled(lang.code),
                    },
                  ]"
                  :to="
                    !isDisabled(lang.code)
                      ? switchLocalePath(lang.code)
                      : undefined
                  "
                >
                  {{ lang.name }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </transition>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
const languageListIsOpen = ref(false)
const router = useRouter()
const switchLocalePath = useSwitchLocalePath()
const { storyblokLocales, availableLocales, currentLocale, isDisabled } =
  useLanguageSwitcher()

const closeList = () => {
  languageListIsOpen.value = false
}
const openList = () => {
  languageListIsOpen.value = true
}

const switchLanguage = (code: string) => {
  if (!storyblokLocales.value.includes(code)) {
    return
  }
  router.push(switchLocalePath(code))
  closeList()
}
</script>

<style lang="scss" scoped>
.slide-x-enter-active,
.slide-x-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) 4ms;
}

.slide-x-move {
  transition: transform 0.6s;
}

.slide-x-enter-from,
.slide-x-leave-to {
  opacity: 0;
  transform: translateX(-15px);
}

.footer__language {
  position: relative;
}

.language-picker {
  position: relative;
  display: flex;
  gap: 6px;
  padding-right: 30px;
  letter-spacing: -0.08px;
  color: var(--cobalt-800);
  cursor: pointer;
  transition: color 0.2s ease;

  .icon__chevron {
    position: absolute;
    top: 6px;
    right: 10px;
    width: 12px;
    height: 12px;
    transition: background-image 0.2s ease;
    background-image: url('@/assets/icons/12-chevron-down-for-footer.svg');
  }

  &__planet {
    width: 24px;
    height: 24px;
    transition: stroke 0.2s ease;
    stroke: var(--color-text);
  }

  &--open .icon__chevron {
    transform: rotate(-180deg);
  }

  &:hover {
    color: var(--color-link);
    text-decoration: none;

    .language-picker__planet {
      stroke: var(--color-link);
    }

    & .icon__chevron {
      background-image: url('@/assets/icons/12-chevron-down-cobalt.svg');
    }
  }
}

.language-list-wrapper {
  position: absolute;
  margin-bottom: 52px;
  bottom: 0;
  left: -4px;
  padding-bottom: 0;

  @media (min-width: $breakpoint-lg) {
    padding-bottom: 52px;
  }
}

.language-list {
  display: block;
  width: 194px;
  padding: 24px;
  background-color: var(--white);
  border-radius: 8px;
  z-index: 20;
  box-shadow:
    0 10px 60px 0 rgb(17 41 80 / 8%),
    0 2px 8px 0 rgb(17 41 80 / 8%);

  &__link {
    position: relative;
    display: block;
    padding: 8px 0 8px 28px;
    color: var(--color-text);

    &:hover {
      color: var(--color-link);
      text-decoration: none;
      cursor: pointer;
    }

    &--disabled {
      color: var(--gray-400);
      cursor: not-allowed !important;
      pointer-events: none;
    }

    &--selected {
      &::before {
        position: absolute;
        top: 11px;
        left: 0;
        width: 16px;
        height: 16px;
        content: '';
        background-image: url('@/assets/icons/16-list-benefit-cobalt.svg');
      }
    }
  }
}
</style>
